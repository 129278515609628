








































































































































































































import Vue from "vue";
import BtnCreateAccessory from "@/views/Settings/buttons/BtnCreateAccessory.vue";

import { mapActions, mapState, mapMutations } from "vuex";
import { getAPI } from "@/api/axios-base";
import {
  notifyError,
  notifyInfo,
  notifySuccess,
} from "@/components/Notification";

export default Vue.extend({
  components: { BtnCreateAccessory },
  name: "accessories",
  data() {
    return {
      search: "",
      name: "",
      uuid: "",
      loading: false,
      dialogConfirmRemove: false,
      DialogEdit: false,
      itemSelect: {},
      headers: [
        { text: "Name", align: "start", value: "name" },
        { text: "Min Price", value: "minValue", sortable: true },
        { text: "Default Price", value: "price", sortable: true },
        { text: "Max Price", value: "maxValue", sortable: true },
        { text: "", align: "end", value: "actions", sortable: false },
      ],
    };
  },
  computed: {
    ...mapState("crmSettingsModule", ["nomenclators"]),
    ...mapState(["dataCRM"]),
    PriceValid() {
      if (
        (this as any).itemSelect.minValue == 0 &&
        (this as any).itemSelect.maxValue == 0 &&
        (this as any).itemSelect.price == 0
      ) {
        return true;
      }
      if (
        (this as any).itemSelect.minValue != 0 ||
        (this as any).itemSelect.maxValue != 0
      ) {
        if (
          Number((this as any).itemSelect.minValue) >
            Number((this as any).itemSelect.maxValue) &&
          Number((this as any).itemSelect.price) <=
            Number((this as any).itemSelect.minValue) &&
          Number((this as any).itemSelect.price) >=
            Number((this as any).itemSelect.maxValue)
        ) {
          return true;
        } else if (
          Number((this as any).itemSelect.minValue) <
            Number((this as any).itemSelect.maxValue) &&
          Number((this as any).itemSelect.price) >=
            Number((this as any).itemSelect.minValue) &&
          Number((this as any).itemSelect.price) <=
            Number((this as any).itemSelect.maxValue)
        ) {
          return true;
        }
        if (
          (this as any).itemSelect.minValue ==
            (this as any).itemSelect.maxValue &&
          (this as any).itemSelect.price == (this as any).itemSelect.minValue
        ) {
          return true;
        }
      }
      return false;
    },
  },
  mounted() {
    this.actListNomenclator("Accessory");
    this.name = this.dataCRM.nomenclators
      ? this.dataCRM.nomenclators.accessories
      : "Accessories";
  },
  methods: {
    ...mapActions("crmSettingsModule", [
      "actListNomenclator",
      "actUpdateNomenclator",
    ]),
    ...mapMutations("crmSettingsModule", ["mutNomencators"]),

    toEdit(item: any) {
      this.DialogEdit = true;
      this.itemSelect = item;
    },
    toDelete(item: any) {
      this.dialogConfirmRemove = true;
      this.itemSelect = item;
    },
    remove(): void {
      this.loading = true;
      getAPI
        .delete(
          `/sprocedures/deleteNom/${(this as any).itemSelect.id}/Accessory`
        )
        .then(async () => {
          await this.listAccessories();
          this.loading = false;
          this.dialogConfirmRemove = false;
          notifyInfo(this.$t("notifyRemove"));
        })
        .catch((error: any) => {
          this.loading = false;
          let mess = error.response.data.message;

          if (mess.includes("[")) {
            mess = mess.replace("[", "");
            mess = mess.replace("]", "");
          }
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    update(): void {
      (this as any).itemSelect.minValue = Number(
        (this as any).itemSelect.minValue
      );
      (this as any).itemSelect.maxValue = Number(
        (this as any).itemSelect.maxValue
      );
      (this as any).itemSelect.price = Number((this as any).itemSelect.price);
      if (
        (this as any).itemSelect.minValue > (this as any).itemSelect.maxValue
      ) {
        const temp = (this as any).itemSelect.minValue;
        (this as any).itemSelect.minValue = (this as any).itemSelect.maxValue;
        (this as any).itemSelect.maxValue = temp;
      }
      let body = {};
      if ((this as any).itemSelect.name == "") {
        this.listAccessories();
        return;
      }

      if (
        (this as any).itemSelect.minValue != 0 &&
        (this as any).itemSelect.maxValue != 0
      ) {
        body = {
          id: (this as any).itemSelect.id,
          name: (this as any).itemSelect.name,
          type: "Accessory",
          minValue: (this as any).itemSelect.minValue,
          maxValue: (this as any).itemSelect.maxValue,
          price: (this as any).itemSelect.price,
        };
      } else {
        if ((this as any).itemSelect.maxValue != 0) {
          body = {
            id: (this as any).itemSelect.id,
            name: (this as any).itemSelect.name,
            type: "Accessory",
            maxValue: (this as any).itemSelect.maxValue,
            price: (this as any).itemSelect.price,
          };
        } else {
          body = {
            id: (this as any).itemSelect.id,
            name: (this as any).itemSelect.name,
            type: "Accessory",
          };
        }
      }
      this.loading = true;
      this.actUpdateNomenclator(body)

        .then(() => {
          this.listAccessories();
          this.cancel();
          notifySuccess(`The ${this.name} has been updated`);
        })
        .catch((error: any) => {
          this.loading = false;
          let mess = error.response.data.message;

          if (mess.includes("[")) {
            mess = mess.replace("[", "");
            mess = mess.replace("]", "");
          }
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },

    cancel() {
      this.DialogEdit = false;
      this.dialogConfirmRemove = false;
      this.itemSelect = {};
      this.loading = false;
    },
    async listAccessories() {
      this.loading = true;
      const accessory = (await getAPI(`/sprocedures/listNom/Accessory`)).data;
      this.loading = false;
      this.mutNomencators(accessory);
    },
  },
});
